@import '../styles/_mixins.scss';

.room-carousel {
  overflow: hidden;
}

.inner {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.carousel-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
        align-items: baseline;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}

.indicators {
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 50px;
}

.indicators > button {
  position: relative;
  list-style: none;
  padding: 10px;
  text-align: center;
  width: 80px;
  margin-right: 20px;
  border: 1px solid $white;
  background-color: $white;
  color: $green;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: $green;
    height: 1px;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

  &:hover:after {
    width: 75%;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  }

}

.indicators > button.active {
  color: $white;
  text-decoration: none;
  background-color: $green;
  border: 1px solid $green;
}

.slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  justify-items: center;
  -ms-flex-line-pack: center;
      align-content: center;

  .col-6 {
    img {
      width: 100%;
    }
  }
}

@include screen-md-breakpoint {

  .carousel-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
      align-items: center;
  }

  .indicators > button {
    width: 200px;
  }

  .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;

    .col-5 {
      margin-left: 50px;
    }
  }
}