// Screen sizes
$screen-sm: 600px;
$screen-md: 900px;
$screen-lg: 1200px;
$screen-xl: 1600px;

// Colors
$black: #000;
$green: #79877a;
$dark-green: #4F5C50;
$grey: #d0cdc6;
$lightgray: #fafafa;
$darkgrey: #707070;
$white: #fff;
$orange: #dd8434;