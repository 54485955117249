@import '../styles/variables';
@import '../styles/mixins';

#map {
    position: relative;
    background: $lightgray;

    .label {
        position: absolute;
        top: -20px;
        transform: rotate(-5deg);

        img {
            width: 100px;
        }
    }

    .col-12 {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-content: center;
        align-items: center;

        .info {
            padding: 70px 20px 80px;

            h2 {
                color: $green;
                margin: 0;
                margin-bottom: 20px;
            }
        }

        ul {
            display: flex;

            li {
                list-style: none;
            }

            li:not(:first-child) {
                margin-left: 10px;
            }
        }

        input, textarea {
            background: transparent;
        }
    }
}

@include screen-md-breakpoint {
    #map {

        .label {
            img {
                width: 125px;
            }
        }

        .col-12 {
            padding: 80px 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;

            .info {
                padding: 40px 0;

                h2 {
                    max-width: 60%;
                }
            }

            ul {
                li:first-child {
                    list-style: none;
                }
    
                li:not(:first-child) {
                    margin-left: 25px;
                }
            }
        }
    }
}
