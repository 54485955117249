@import './mixins';
@import './variables';

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Bold.eot');
    src: url('../fonts/Graphik-Bold.eot') format('embedded-opentype'),
        url('../fonts/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/Graphik-Bold.woff') format('woff'),
        url('../fonts/Graphik-Bold.ttf') format('truetype'),
        url('../fonts/Graphik-Bold.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Light.eot');
    src: url('../fonts/Graphik-Light.eot') format('embedded-opentype'),
        url('../fonts/Graphik-Light.woff2') format('woff2'),
        url('../fonts/Graphik-Light.woff') format('woff'),
        url('../fonts/Graphik-Light.ttf') format('truetype'),
        url('../fonts/Graphik-Light.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'HoeflerTitling';
    src: url('../fonts/HoeflerTitling-HTF-BdRoman.eot');
    src: url('../fonts/HoeflerTitling-HTF-BdRoman.eot') format('embedded-opentype'),
        url('../fonts/HoeflerTitling-HTF-BdRoman.woff2') format('woff2'),
        url('../fonts/HoeflerTitling-HTF-BdRoman.woff') format('woff'),
        url('../fonts/HoeflerTitling-HTF-BdRoman.ttf') format('truetype'),
        url('../fonts/HoeflerTitling-HTF-BdRoman.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'HoeflerTitling';
    src: url('../fonts/HoeflerTitling-HTF-ltroman.eot');
    src: url('../fonts/HoeflerTitling-HTF-ltroman.eot') format('embedded-opentype'),
        url('../fonts/HoeflerTitling-HTF-ltroman.woff2') format('woff2'),
        url('../fonts/HoeflerTitling-HTF-ltroman.woff') format('woff'),
        url('../fonts/HoeflerTitling-HTF-ltroman.ttf') format('truetype'),
        url('../fonts/HoeflerTitling-HTF-ltroman.svg') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Regular.eot');
    src: url('../fonts/Graphik-Regular.eot') format('embedded-opentype'),
        url('../fonts/Graphik-Regular.woff2') format('woff2'),
        url('../fonts/Graphik-Regular.woff') format('woff'),
        url('../fonts/Graphik-Regular.ttf') format('truetype'),
        url('../fonts/Graphik-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

h1, h2, h3, h4, h5, h5, p, a, span {
    font-weight: normal !important;
}

h1 {
    margin-bottom: 1em;
    font-family: HoeflerTitling;
    font-weight: 400;
}

h2 {
    color: $green;
    font-family: HoeflerTitling;
    font-size: 35px;
    line-height: 50px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
}

h3 {
    margin-top: 30px;
    font-family: Graphik;
    color: $green;
    font-weight: 400;
}

h4 {
    font-family: Graphik;
    color: $green;
    font-weight: 400;
}


p, li, a, label {
    font-weight: 400;
    color: $green;
    font-family: Graphik;
    font-size: 14px;
    line-height: 24px;
}

p {
    margin: 10px 0;
}

span[role=alert] {
    position: absolute;
    top: 8px;
    right: 0;
    font-size: 10px;
    color: $orange;
    font-family: Graphik !important;
    border-top: 0;
}

input, textarea {
    padding: 5px;
    font-weight: 400;
    color: $dark-green;
    font-family: Graphik !important;
    font-size: 14px !important;
}

button {
    font-family: Graphik !important;
}

.subhead, label {
    text-transform: uppercase;
    color: $green;
    font-family: Graphik;
}

label {
    margin-bottom: 5px;
}

.subhead {
    margin: 0;
    margin-bottom: 10px;
}