.fadeInUp {
  animation: fadeInUp 1.5s ease backwards;
}

.fadeIn {
  animation: fadeIn 2.5s;
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 20px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from { opacity: 0.25; }
  to   { opacity: 1; }
}