@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

nav.stroke ul li a {
  position: relative;
}

nav.stroke ul li a:hover:after {
  width: 0%;
}

nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: $white;
  height: 1px;
}

.navigation .logo {
  padding: 19px 20px;
  position: absolute;

  .desktop {
    display: none;
  }
}

nav ul li a,
nav ul li a:after,
nav ul li a:before {
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}
nav.stroke ul li a:hover {
  color: $dark-green;
}

  .parent-nav {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $lightgray;
    width: 100%;
    height: 64px;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $lightgray;
    z-index: 3;
  }

  .col-12.navigation {
    padding: 0px;
  }

  #contact {
    margin: 20px;
    text-align: center;
    background: $green;
    color: $white;
    -webkit-transition: background .5s;
    -o-transition: background .5s;
    transition: background .5s;

    &:hover {
      background: $dark-green;
      -webkit-transition: background .5s;
      -o-transition: background .5s;
      transition: background .5s;
    }
  }
  
  .navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
  }
  
  .navigation li a {
    display: block;
    padding: 20px;
    text-decoration: none;
  }
  
  .navigation li a:hover,
  .navigation .menu-btn:hover {
    color: $green;
  }
  
  /* menu */
  
  .navigation .menu {
    clear: both;
    max-height: 0;
    -webkit-transition: max-height .5s ease-out;
    -o-transition: max-height .5s ease-out;
    transition: max-height .5s ease-out;
  }
  
  /* menu icon */
  
  .navigation .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  .navigation .menu-icon .navicon {
    background: $green;
    display: block;
    height: 2px;
    position: relative;
    -webkit-transition: background .2s ease-out;
    -o-transition: background .2s ease-out;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .navigation .menu-icon .navicon:before,
  .navigation .menu-icon .navicon:after {
    background: $green;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .navigation .menu-icon .navicon:before {
    top: 5px;
  }
  
  .navigation .menu-icon .navicon:after {
    top: -5px;
  }

  .navigation.open .menu {
    height: 100vh;
    max-height: 100vh;
  }
  
  .navigation.open .menu-btn .menu-icon .navicon {
    background: transparent;
  }
  
  .navigation.open .menu-btn .menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .navigation.open .menu-btn .menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .navigation.open .menu-btn .menu-icon:not(.steps) .navicon:before,
  .navigation.open .menu-btn .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  @include screen-md-breakpoint {

    .navigation {
      display: inline-block;

      .logo .desktop {
        width: 150px;
        display: flex;
      }
    }

    .parent-nav {
      top: 20px;
    }

    .navigation li {
      float: left;
    }
    .navigation li a {
      padding: 20px 30px;

      &#contact {
        margin: 0;
        padding: 20px 40px;

      }
    }
    .navigation .menu {
      clear: none;
      float: right;
      max-height: none;
    }

    nav.stroke ul li a:hover:after {
      width: 75%;
    }

    .navigation .menu-icon {
      display: none;
    }

    .navigation .logo {
      display: block;
      float: left;
      font-size: 2em;
      padding: 10px 40px;
      text-decoration: none;

      .mobile {
        display: none;
      }
  
      svg {
          height: 42px;
      }
    }
  
    a.logo:hover {
      svg path {
        fill: $dark-green !important;
      }
    }
  }

  @include screen-md-breakpoint {
    .parent-nav {
      background-color: transparent;
      display: flex;
    }

    .navigation {
      background-color: transparent;
    }
}