@import '../styles/mixins';
@import '../styles/variables';

#booking-link {
    text-align: center;

    p {
        font-size: 1em;
    }
}

.booking-button {
    display: flex;
    width: auto;
    color: white;
    margin: 0 auto;
    height: 40px;
    background: $green;
    justify-content: center;
    align-items: center;
    max-width: 340px;
    transition: all .2s ease-out;
    text-decoration: none;

    &:hover {
        background: $dark-green;
        transition: all .2s ease-out;
    }
}

@include screen-md-breakpoint {
    .booking-button {
        max-width: 400px;
    }
}