@import '../styles/_variables.scss';
@import '../styles/_animations.scss';
@import '../styles/_mixins.scss';

#contact-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .col-12.form-row {
        padding: 0 !important;
        align-items: flex-start !important;
        margin-bottom: 1em;
    }
}

.form-row {
    label {
        color: $dark-green;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.col-12.form-row {

    p {
        text-align: left;
        margin: 0;
    }
}

.form-row.full {
    width: 100%;
}

input.col-12 {
    padding: 10px;
    color: #fff;
    text-decoration: none;
    background-color: #79877a;
    border: 1px solid #79877a;
}

input[type=submit] {
    color: $white;
    background: $green !important;
    display: block;
    width: 100%;
    transition: all .2s ease-in-out;        

    cursor: pointer;

    &:hover {
        background-color: $dark-green !important;
        transition: all .2s ease-in-out;        
    }
}

@include screen-sm-breakpoint {
    .col-6.form-row {
        width: 49%;
    }
}

.status-message {
    &.succes {
        color: $green;
    }

    &.failure {
        color: $orange;
    }
}