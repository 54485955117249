@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

.footer {
  padding: 20px;
  background-color: $dark-green;
  z-index: 3;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer .col-12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.footer ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer li a, .footer li p {
  color: white;
  line-height: 24px;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.footer li a:hover,
.footer .menu-btn:hover {
  color: $grey;
}
  
.footer svg {
  fill: white !important;
  height: 15px;
  margin-right: 5px;
}
  
.footer .info a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@include screen-md-breakpoint {
  .footer .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .footer .info a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  
  .info ul:nth-child(1) {
    margin-right: 50px;
  }

  .footer li {
    float: left;
  }

  .footer .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  .footer .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  
    svg {
        height: 42px;
    }
  }
}