@import '../styles/_variables.scss';
@import '../styles/_animations.scss';
@import '../styles/_mixins.scss';

.hero {
    display: flex;
    align-items: center;
    width: 100%;
    background-image: url('../images/hero/hero_edit.jpeg');
    min-height: 100vh;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    justify-content: center;

    p, a, h1 {
        color: $white;
        margin: 0;
    }

    h1 {
        font-style: italic;
        font-size: 2.75em;
        line-height: 1.25em;
        margin-bottom: 1em;
    }

    .info {
        position: absolute;
        top: 100px;
        max-width: 750px;
        padding: 20px;
    }

    .bottom {
        text-align: center;
        position: absolute;
        bottom: 25px;

        p {
            margin-bottom: 10px;
            letter-spacing: 0.05em;
            font-size: 1em
        }

        a {
            position: relative;
            font-weight: 400;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            &::after {
                transition: all 0.1s ease-in-out;
                display: block;
                bottom: -4px;
                left: 0;
                content: '';
                background: white;
                position: absolute;
                height: 0.25px;
                width: 100%;
            }
        }
        .charging {
            display: flex;
            justify-content: center; 
            img {
                width: 32px;
                height: 32px;
            }         
            p {
                margin: 0;
                margin-left: 10px;
                margin-right: 10px;
            }
        }      
    }

    .button {
        position: relative;
        display: block;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-top: 20px;

        &::after {
            position: absolute;
            bottom: 0;
            transition: all 0.1s ease-in-out;
            display: block;
            left: calc(50% - 140px);
            content: '';
            background: white;
            position: absolute;
            height: 0.25px;
            width: 280px;
        }

        &:hover {
            transition: all 0.1s ease-in-out;
            opacity: 0.9;

            &:after {
                transition: all 0.1s ease-in-out;
                bottom: -2px;
                opacity: 0.9;

            }
        }
    }

    .book-button {
        transform: translateX(25px);
        position: relative;
        border-radius: 0 20px 20px 0;
        padding: 10px 25px;
        text-transform: uppercase;
        display: inline-block;
        background: $green;
        letter-spacing: 0.1em;

        &:hover {
            transition: all 0.2s ease-in-out;
            background: $dark-green;

            &:before {
                transition: all 0.2s ease-in-out;
                background: $dark-green;
            }
        }

        &:before {
            border-right: $grey 1px solid;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 50px;
            border-radius: 20px 0 0 20px;
            left: -50px;
            top: 0;
            position: absolute;
            content: url('../svg/logo_white.svg');
            background: $green;
        }
    }

    .info {
        text-align: center;
    }
}

@include screen-sm-breakpoint {
    .hero {
        .info {
            top: 150px;
        }

        h1 {
            font-size: 4.5em;
        }

        .bottom {
            bottom: 50px;
            display: inherit;

            p {
                margin: 0;
                margin-right: 20px;
            }
        }

        span {
            margin-left: 20px;
            position: relative;           
        }

        a.locatie:hover {
            transition: all 0.1s ease-in-out;
            opacity: 0.9;

            &:after {
                transition: all 0.1s ease-in-out;
                bottom: -6px;
                opacity: 0.9;

            }
        }

        span:before {
            left: -20px;
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 1px;
            background: $grey;
        }
    }
}