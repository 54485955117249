
@import '../styles/variables';
@import '../styles/mixins';

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    

    .image {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &.reverse {
        flex-direction: column-reverse;
    }

    &.reverse-row {
        flex-direction: column-reverse;
    }
}

@include screen-md-breakpoint {
    section {
        flex-direction: row;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 60px 0 60px 60px;
        }

        &#voorzieningen {
            padding-top: 60px;
            .image {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    max-height: 550px;
                }
            }
        }

        &.reverse {
            flex-direction: row;
            .content {
                padding: 60px 60px 60px 0;
            }
        }

        &:nth-child(even) .image {
            order: 2
        }

        &.reverse-row {
        flex-direction: row;

        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 60px 60px 60px 0;
        }
    
        &:nth-child(even) .image {
            order: 2
        }
    
        &:nth-child(even) .content {
            padding: 60px 0 60px 60px;
        } 
    }
}
}