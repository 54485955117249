@import '../styles/_variables.scss';
@import '../styles/mixins';

.carousel {
  margin-top: 40px;

  .flickity-slider {
    img {
      object-fit: cover;
      margin: 0 5px;
    }
  }
}

.flickity-enabled {
  position: relative;
}
  
.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  min-height: 40vh;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

// .flickity-slider img {
//   margin: 10px;
// }
  
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  display: none;
  position: absolute;
  border: none;
  color: #333;
}

.flickity-button:hover {
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: $white;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  background: transparent;
  bottom: 60px;
  width: 122px;
  height: 16.5px;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 140px;
}
.flickity-prev-next-button.next {
  right: 140px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
} 

@include screen-md-breakpoint {
  .carousel {
    margin-top: 60px;

    &.room {
      margin-top: 0;
    }
  }
}