@import '../styles/variables';
@import '../styles/mixins';

.pricing {
    padding: 20px;

    .content {
        h2 {
            margin-bottom: 15px
        }
        p {
            margin: 0;
            margin-bottom: 15px;
        }
    }

    .heading {
    border-top: 1px solid $green;
    border-bottom: 1px solid $green;
    margin: 10px 0;
        p {
            margin: 10px 0;
            text-align: left;
            font-size: 1.25em !important;
            font-weight: 800 !important;
            line-height: 30px;
        }    
    }
    
    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        p {
            margin: 0;
        }
    }
}

@include screen-md-breakpoint {
    .col-12.pricing {
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .col-3 {
        width: 23%;
    }

    .col-12 {
        margin-bottom: 20px;

        p {
            margin-bottom: 0;
        }
    }

    .col-12.info {
        margin-bottom: 20px;
    }

    .col-8 {
        width: 65%;

        p {
            margin: 0;
        }
    }

    .content.col-12 {
        padding: 0;
    }
}